
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { expandMenuItem } from '../store/actions'

interface MenuItemProps {
  item: IMenuItem
}

interface MenuItemComputed {
  menu: IMenuItem
  currentLevel: number
  isMobile: boolean
  currentPageId: string
}

interface MenuItemMethods {
  expandMenuItem(item: IMenuItem): void
  filterChildren(children: any[]): any[]
  checkExpanded(): boolean
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>({
  name: 'MenuItem',
  props: {
    item: {},
  },
  computed: {
    menu() {
      return this.$store.getters.menu
    },
    currentLevel(){
      return this.$store.getters.menuBreadCrumbs.length
    },
    isMobile(){
      return this.$store.getters.isMobile
    },
    currentPageId(){
      return this.$store.state.currentPageId
    },
  },
  methods: {
    expandMenuItem(item) {
      this.$store.dispatch(expandMenuItem(item))
    },
    filterChildren(children) {
      const filteredChildren = children.filter((child) => {
        return child.isExpanded === true || child.level >= this.currentLevel
      })
      return filteredChildren;
    },
    checkExpanded() {
      if(this.item.isExpanded) return this.currentLevel <= this.item.level
      else if(!this.item.isExpanded) return this.currentLevel < this.item.level
      else return false
    }
  }
})
