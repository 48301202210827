
import Vue from 'vue'
import { ITopLinks } from '../../store/state'
import Languages from '../Languages.vue'
import Logo from '../Logo.vue'
import MobileMenuButton from './MobileMenuButton.vue'
import SearchButton from '../SearchButton.vue'
import SearchForm from '../SearchForm.vue';

interface TopHeaderComputed {
	topLinks: ITopLinks
  showMenu: boolean
}

interface TopHeaderMethods {
}

export default Vue.extend<{}, TopHeaderMethods, TopHeaderComputed, {}>({
	name: 'TopHeader',
  computed: {
    topLinks() {
      return this.$store.getters.topLinks;
    },
    showMenu() {
      return this.$store.getters.showMenu;
    },
  },
  methods: {
  },
  components: {
    Languages,
    Logo,
    MobileMenuButton,
    SearchButton,
    SearchForm
  }
})
