
import Vue from 'vue'
import { ITopLinks } from '../store/state'
import Languages from './Languages.vue'
import Logo from './Logo.vue'
import SearchButton from './SearchButton.vue'
import SearchForm from './SearchForm.vue';

interface TopHeaderComputed {
	topLinks: ITopLinks
}

interface TopHeaderMethods {
}

export default Vue.extend<{}, TopHeaderMethods, TopHeaderComputed, {}>({
	name: 'TopHeader',
  computed: {
    topLinks() {
      return this.$store.getters.topLinks;
    },
  },
  methods: {
  },
  components: {
    Languages,
    Logo,
    SearchButton,
    SearchForm
  }
})
