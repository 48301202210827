
import Vue from 'vue'
import TopHeader from './components/TopHeader.vue';
import Menu from './components/Menu.vue';
import MenuButtons from './components/MenuButtons.vue';
import MobileTopHeader from './components/mobile/MobileTopHeader.vue';
import MobileMenu from './components//mobile/MobileMenu.vue';
import { checkMobile } from './store/actions'
import { FocusTrap } from 'focus-trap-vue'
Vue.component('FocusTrap', FocusTrap);

interface AppComputed {
	isMobile: boolean
	showMenu: boolean
}

export default Vue.extend<{}, {}, AppComputed, {}>({
	name: 'app',
	computed: {
		isMobile() {
			return this.$store.getters.isMobile
		},
		showMenu() {
			return this.$store.getters.showMenu
		}
	},
	created() {
		this.$store.dispatch(checkMobile(window.innerWidth))

		addEventListener('resize', () => {
			const width = Math.max(
				document.body.scrollWidth,
				document.documentElement.scrollWidth,
				document.body.offsetWidth,
				document.documentElement.offsetWidth,
				document.documentElement.clientWidth,
				window.innerWidth
				);
			this.$store.dispatch(checkMobile(width))
		})
	},
	components: {
		TopHeader,
		MobileTopHeader,
		Menu,
		MobileMenu,
		MenuButtons,
	}
})
