
import Vue from 'vue'

interface SearchButtonProps {
  id: string
}

interface SearchButtonComputed {
  showSearch: boolean
  isMobile: boolean
}

interface SearchButtonMethods {
  toggleSearch(): void
  buttonString(mobile: boolean, returnString: string): string
}

export default Vue.extend<{}, SearchButtonMethods, SearchButtonComputed, SearchButtonProps>({
	name: 'SearchButton',
  props: {
    id: {
      type: String,
      required: true
    },
  },
  computed: {
    showSearch() {
      return this.$store.getters.showSearch
    },
    isMobile() {
      return this.$store.getters.isMobile
    }
  },
  methods: {
    toggleSearch() {
      this.$store.dispatch({
          type: 'toggleSearch',
      })
    },
    buttonString(isMobile, returnString) {
      if (isMobile) return ''
      return returnString
    }
  },
})
