
import Vue from 'vue'

interface SearchFormProps {
  id: string
}

interface SearchFormComputed {
  showSearch: boolean
  searchPageUri: string
}

interface SearchFormMethods {}

export default Vue.extend<{}, SearchFormMethods, SearchFormComputed, SearchFormProps>({
	name: 'SearchForm',
  props: {
    id: {
      type: String,
      required: true
    },
  },
  computed: {
    showSearch() {
      return this.$store.getters.showSearch;
    },
    searchPageUri() {
      return this.$store.getters.searchPageUri;
    }
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', () => {
      $svjq(".hiq-header__search-input-text").rekAutoComplete();
    })
  }
})
