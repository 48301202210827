export interface IState {
	topLinks: ITopLinks
	menu: IMenuItem[]
	mobileMenu: IMenuItem[]
	isMobile: boolean
	logo: ILogo
	startPage: object
	showLanguages: boolean
	showMenu: boolean
	showSearch: boolean
	currentPageId: string
	endpoints: {
		getChildren: string
		getMenu: string
		searchPageUri: string
	}
	menuBreadCrumbs: IMenuItem[]
}

export interface ILogo {
	uri: string
	altText: string
}

export interface ILanguageItem {
	uri: string
	name: string
}

export interface IMenuItem {
	name: string
	uri: string
	id: string
	isCurrent: boolean
	isExpanded: boolean
	hasChildren: boolean
	level: number
	children: IMenuItem[]
}

export interface ITopLinks {
	municipalityMap: string
	languages: ILanguageItem[]
}

const defultState: IState = {
	topLinks: {
		municipalityMap: '',
		languages: [],
	},
	menu: [],
	mobileMenu: [],
	isMobile: false,
	logo: {
		uri: '',
		altText: ''
	},
	startPage: {},
	showMenu: false,
	showLanguages: false,
	showSearch: false,
	endpoints: {
		getChildren: '',
		getMenu: '',
		searchPageUri: ''
	},
	menuBreadCrumbs: [],
	currentPageId: ''
}

export default defultState;
