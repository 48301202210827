
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { toggleMenu, unexpandMenuItems, deactivateCurrentItem } from '../store/actions'
import MenuItem from './MenuItem.vue';

interface MenuMethods {
  closeMenu(): void
  deactivateCurrentItem(): void
  filterChildren(children: any[]): any[]
}

interface MenuComputed {
  activeMenuItem: IMenuItem
  currentLevel: number
  showMenu: boolean
  isMobile: boolean
}

export default Vue.extend<{}, MenuMethods, MenuComputed, {}>({
	name: 'Menu',
  computed: {
    activeMenuItem() {
      return this.$store.getters.activeMenuItem
    },
    currentLevel(){
      return this.$store.getters.menuBreadCrumbs.length
    },
    showMenu(){
      return this.$store.getters.showMenu
    },
    isMobile(){
      return this.$store.getters.isMobile
    },
  },
  methods: {
    closeMenu() {
      this.$store.dispatch(toggleMenu({
        open: false
      }))
      this.$store.dispatch(unexpandMenuItems(undefined))
      const html = document.documentElement;
      html.removeAttribute('menu-open');
    },
    deactivateCurrentItem() {
      this.$store.dispatch(deactivateCurrentItem(undefined))
    },
    filterChildren(children) {
      const filteredChildren = children.filter((child) => {
        return child.isExpanded === true || child.level >= this.currentLevel
      })
      return filteredChildren;
    }
  },
  mounted() {
    !this.isMobile && window.addEventListener('click', (e) => {
      const appRef = this.$refs.menu as HTMLElement;
      const target = e.target as HTMLElement;
      if((appRef?.contains(target)) || target.dataset?.ref === 'menu-button' || target.hasAttribute('data-ignore-close')) return;
      if(this.showMenu) this.closeMenu();
    })
  },
  components: {
      MenuItem
  }
})
