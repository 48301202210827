
import Vue from 'vue'
import { IMenuItem } from '../../store/state'
import { deactivateCurrentItem } from '../../store/actions'
import MenuItem from '../MenuItem.vue';

interface MenuMethods {
  deactivateCurrentItem(): void
}

interface MenuComputed {
	mobileMenu: IMenuItem
  currentLevel: number
}

export default Vue.extend<{}, MenuMethods, MenuComputed, {}>({
	name: 'Menu',
  computed: {
    mobileMenu() {
      return this.$store.getters.mobileMenu
    },
    currentLevel(){
      return this.$store.getters.menuBreadCrumbs.length
    },
  },
  methods: {
    deactivateCurrentItem() {
      this.$store.dispatch(deactivateCurrentItem(undefined))
    }
  },
  components: {
    MenuItem
  }
})
