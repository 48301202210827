
import Vue from 'vue'
import { toggleMenu, unexpandMenuItems } from '../../store/actions';
import { IMenuItem } from '../../store/state'
import MenuItem from '../MenuItem.vue';

interface MobileMenuButtonMethods {
  openMenu(menuItem: IMenuItem): void
}

interface MobileMenuButtonComputed {
	showMenu: boolean
}

export default Vue.extend<{}, MobileMenuButtonMethods, MobileMenuButtonComputed, {}>({
	name: 'MobileMenuButton',
  computed: {
    showMenu() {
      return this.$store.getters.showMenu
    },
  },
  methods: {
    openMenu() {
      this.$store.dispatch(toggleMenu({
        open: !this.showMenu
      }))
      
      const html = document.documentElement;
      if(!this.showMenu) {
        this.$store.dispatch(unexpandMenuItems(undefined))
        html.removeAttribute('menu-open');
      } else {
        html.setAttribute('menu-open', '');
      }
    }
  },
  components: {
    MenuItem
  }
})
