
import Vue from 'vue'
import { toggleMenu } from '../store/actions';
import { IMenuItem } from '../store/state'
import MenuItem from './MenuItem.vue';

interface MenuMethods {
  openMenu(menuItem: IMenuItem): void
}

interface MenuComputed {
	menu: IMenuItem
}

export default Vue.extend<{}, MenuMethods, MenuComputed, {}>({
	name: 'Menu',
  computed: {
    menu() {
      return this.$store.getters.menu
    },
  },
  methods: {
    openMenu(menuItem) {
      this.$store.dispatch(toggleMenu({
        menuItem: menuItem,
        open: true
      }))
      const html = document.documentElement;
      html.setAttribute('menu-open', '');
    }
  },
  components: {
    MenuItem
  }
})
