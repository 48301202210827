import { IGetChildrenPayload } from '@/store/actions'
import { IMenuItem } from '@/store/state'
import axios from 'axios'

const headers = {
	'X-Requested-With': 'XMLHttpRequest',
	'Accept': 'application/json',
}

const stringifyParameters = (paramObject: any) => {
    let paramArray: string[] = [];
    Object.keys(paramObject).forEach(key => {
        let paramString = `${key}=${paramObject[key]}`;
        paramArray.push(paramString);
    })
    let returnString = '?' + paramArray.join('&');
    return returnString;
}

export const getMenuItems = (searchURL: string, params: IGetChildrenPayload): Promise<IMenuItem[]> => {
    let stringifiedParameters = stringifyParameters({
        id: params.id,
        level: params.level
    })
    let url = searchURL + stringifiedParameters;
    return axios.get(url, { headers })
        .then((response:any) => {
            return response.data
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IMenuItem[]
        })
}