
import Vue from 'vue'
import { ILanguageItem } from '../store/state'

interface LanguagesProps {
	languages: ILanguageItem[]
}

interface LanguagesComputed {
  showLanguages: boolean
}

interface LanguagesMethods {
  toggleLanguages(): void
}

export default Vue.extend<{}, LanguagesMethods, LanguagesComputed, LanguagesProps>({
	name: 'Languages',
	props: {
    languages: [],
	},
  computed: {
    showLanguages() {
      return this.$store.getters.showLanguages;
    }
  },
  methods: {
    toggleLanguages() {
      this.$store.dispatch({
        type: 'toggleLanguages'
      })
    }
  },
  mounted() {
    window.addEventListener('click', (e) => {
      const appRef = this.$refs.languages as HTMLElement;
      const target = e.target as HTMLElement;
      if(appRef && !appRef.contains(target)) {
        if(this.showLanguages && !target.hasAttribute('data-ignore-close')) {
          this.toggleLanguages()
        }
      }
    })
  }
})
